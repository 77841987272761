

import React, { useEffect, useState } from 'react';

const HomePage = () => {
  const [data, setData] = useState(null);

  useEffect(() => {
    // Function to parse query parameters from the URL
    const getQueryParam = (name) => {
      const urlParams = new URLSearchParams(window.location.search);
      return urlParams.get(name);
    };

    // Get the 'token' query parameter from the URL
    const token = getQueryParam('token');

    // Check if the 'token' is available
    if (token) {
      // API endpoint to make a call to the backend
      const backendEndpoint = 'https://your-backend-api.com/data';

      // Make a call to the backend using fetch or any HTTP library of your choice
      fetch(`${backendEndpoint}?token=${token}`)
        .then((response) => response.json())
        .then((result) => {
          setData(result);
        })
        .catch((error) => {
          console.error('Error fetching data from the backend:', error);
        });
    }
  }, []); // The empty dependency array ensures the useEffect runs only once when the component mounts

  return (
    <div>
      <h1>Welcome to the Triveni Online Store</h1>
      {data && (
        <div>
          <h2>Data from Backend:</h2>
          <pre>{JSON.stringify(data, null, 2)}</pre>
        </div>
      )}
    </div>
  );
};

export default HomePage;
